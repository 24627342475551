import React, { } from "react";
import Layout from '../components/Layout/Layout';
import { graphql, Link } from 'gatsby';
//import Helmet from 'react-helmet';
import { decode } from 'html-entities';

import { useListServicesQuery } from "../hooks/useListServicesQuery"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Seo from 'gatsby-plugin-wpgraphql-seo';
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function ServicesPost({ data }) {
  const { allWpPost } = useListServicesQuery();
  const sideServicesMenu = allWpPost.edges

  const post = data.post;
  const servicesData = data.post.serviceContent;
  const testimonial = data.testimonials.edges;
//   console.log('testimonial', testimonial)
//   console.log('post service data', post)

  let sliderData = []
  if (!data.post.repeatableFields) return null
  const content1 = JSON.parse(data.post.repeatableFields);
  //console.log('content1:: ',content1)
  const aa = content1[0];
  if(testimonial.length > 0){
    testimonial.map((tm) => {
        if(tm.node.content === null){
            sliderData.push(tm)
        }
    })
  }
  //console.log('sliderData:: ',sliderData)
  

  //const sideServicesMenu = services.menuItems.nodes;
  //console.log('sideServicesMenu::: ', sideServicesMenu)
  const settings = {
    infinite: true,
    speed: 2000,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 5000,
  };
  return (
    <Layout>
        <Seo post={post} />
      <section
        className='pageHeader'
        style={{
          background:
            post.featuredImage != null
              ? `url(${post.featuredImage.node.mediaItemUrl})`
              : '#d67373',
        }}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-sm-12 col-md-12 col-lg-12'>
              <nav>
                <ol className='breadcrumb'>
                <li className='breadcrumb-item'><Link to="/">Home</Link></li>
                  <li className='breadcrumb-item active'>{post.title}</li>
                </ol>
              </nav>
              <h1 className='pagetitle__heading entry-header-inner'>{post.title}</h1>
            </div>
          </div>
        </div>
      </section>

      <section className='main-service-section commonSection'>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-6'>
              <h2>{post.serviceContent.serviceIntroduction.title}</h2>
              <div
                className='text-justify'
                key='01'
                dangerouslySetInnerHTML={{
                  __html: post.serviceContent.serviceIntroduction.content,
                }}
              />
            </div>
            <div className='col-sm-6'>
              {post.serviceContent.backImage != null ? (
                <div className='ab_img1'>
                  <img src={post.serviceContent.backImage.sourceUrl} />
                </div>
              ) : (
                ''
              )}

              {post.serviceContent.frontImage != null ? (
                <div className='ab_img2'>
                  <img src={post.serviceContent.frontImage.sourceUrl} />
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </section>
      <section className='main-service-section pt-20 pb-20'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-4'>
              <div className='widget-area primary-sidebar'>
                <div className='widget widget-nav-menu'>
                  <h4 className='widget-title'>Services</h4>
                  <div className='menu-services-menu-container'>
                    <ul className='menu' id='menu-services-menu'>
                      {sideServicesMenu.map((item,i) => {
                        return (
                          <li key={i} className={(post.uri === item.node.uri ? 'current-menu-item' : '')} >
                            <a href={item.node.uri}>{item.node.title}</a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-8'>

              {
                content1 && content1.length > 0 && content1[0] && content1[0].length > 0 && content1[0].map((item, i) => {
                  return (
                    <div key={i}>
                      <h3>{item.title}</h3>
                      <p><b>{item.subtitle}</b></p>
                      <div style={{ paddingBottom: '30px', lineHeight: '27px' }}
                        key={item.title}
                        dangerouslySetInnerHTML={{
                          __html: decode(item.description),
                        }}
                      />

                      </div>

                    
                  )
                })
              }
            </div>
          </div>
        </div>
      </section>

      <section
        className='main-service-section bg-overlay bg-parallax counters-white bg-img'
        style={{
          backgroundImage: `url(
            ${servicesData.background.backgroundImage.sourceUrl}
          )`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
        }}
      >
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <h2 className='color-white'>
                {servicesData.background.backgroundTitle}
              </h2>
              <p className='color-white'>
                {servicesData.background.backgroundDescription}
              </p>
              <div className='row'>
                <div className='col-sm-3 col-xs-6'>
                  <div className='feature-box'>
                    <div className='feature-icon'></div>
                    <h4 className='color-white'>
                      {servicesData.background.features.featureValue1}
                    </h4>
                  </div>
                </div>
                <div className='col-sm-3 col-xs-6'>
                  <div className='feature-box'>
                    <div className='feature-icon'></div>
                    <h4 className='color-white'>
                      {servicesData.background.features.featureValue2}
                    </h4>
                  </div>
                </div>
                <div className='col-sm-3 col-xs-6'>
                  <div className='feature-box'>
                    <div className='feature-icon'></div>
                    <h4 className='color-white'>
                      {servicesData.background.features.featureValue3}
                    </h4>
                  </div>
                </div>
                <div className='col-sm-3 col-xs-6'>
                  <div className='feature-box'>
                    <div className='feature-icon'></div>
                    <h4 className='color-white'>
                      {servicesData.background.features.featureValue4}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='main-service-section'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <h2>Projects in the field</h2>
              <div>
                <Slider {...settings}>
                  {sliderData.map((tm) => {
                       const image = getImage(tm.node.featuredImage.node.localFile)
                    return (
                      <div key={tm.node.id}>
                        <div className='review-box'>
                          <div className='media'>
                            {/* <img
                              className='mr-3'
                              src={tm.node.featuredImage.node.sourceUrl}
                              alt='Generic placeholder image'
                            /> */}
                             <GatsbyImage image={image} alt={tm.node.featuredImage.node.title} />
                          </div>
                          <p className='read'>{tm.node.title}</p>
                          {/* <img
                            className='quote'
                            alt=''
                          /> */}
                        </div>
                      </div>
                    );
                  })}

                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}
export const query = graphql`
  query ($slug: String!) {
    post: wpPost(slug: { eq: $slug }) {
      id
      uri
      title
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
      repeatableFields
      categories {
        nodes {
          id
          link
        }
      }
      content
      featuredImage {
        node {
          mediaItemUrl
        }
      }
      serviceContent {
        serviceIntroduction {
          title
          content
        }
        frontImage {
          sourceUrl
        }
        backImage {
          sourceUrl
        }
        background {
          backgroundTitle
          backgroundDescription
          backgroundImage {
            sourceUrl
          }
          features {
            featureValue1
            featureValue2
            featureValue3
            featureValue4
          }
        }
      }
    }
    testimonials: allWpEasytestimonial {
      edges {
        node {
          id
          title
          content
          featuredImage {
            node {
              sourceUrl
              localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
            }
          }
        }
      }
    }

    # allWpPost(filter: { slug: { eq: $slug } }) {
    #   nodes {
    #     title
    #     content
    #   }
    # }
  }
`;
